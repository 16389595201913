var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.theme.showNotice
    ? _c(
        "el-badge",
        { attrs: { value: _vm.badge } },
        [
          _c("vab-icon", {
            attrs: { icon: "notification-line" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/systemSet/messageSet")
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }