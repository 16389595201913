<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  console.log('当前环境是,', process.env.NODE_ENV)
  export default {
    name: 'App',
  }
</script>
<style>
  .index-container {
    padding: 20px;
  }
</style>
